import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const MicPulseIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M9 13C9.07028 13 9.14 12.9976 9.20907 12.9928C9.31908 12.6059 9.47039 12.2363 9.65793 11.8892C9.45189 11.961 9.2305 12 9 12C7.89543 12 7 11.1046 7 9.99998V5C7 3.89543 7.89543 3 9 3C10.1046 3 11 3.89543 11 5V9.99998C11 10.0924 10.9937 10.1833 10.9816 10.2724C11.2937 10.0124 11.6351 9.78627 12 9.59971V5C12 3.34315 10.6569 2 9 2C7.34315 2 6 3.34315 6 5V9.99998C6 11.6568 7.34315 13 9 13ZM4.5 9.99998C4.5 12.4853 6.51472 14.5 9 14.5C9 15.3189 9.179 16.0961 9.5 16.7945V17.5C9.5 17.7761 9.27614 18 9 18C8.72386 18 8.5 17.7761 8.5 17.5V15.4776C5.69675 15.2249 3.5 12.869 3.5 9.99998C3.5 9.72384 3.72386 9.49998 4 9.49998C4.27614 9.49998 4.5 9.72384 4.5 9.99998ZM19 14.5C19 12.0147 16.9853 10 14.5 10C12.0147 10 10 12.0147 10 14.5C10 16.9853 12.0147 19 14.5 19C16.9853 19 19 16.9853 19 14.5ZM13.444 12.5002C13.6496 12.5066 13.8303 12.6382 13.8994 12.8318L14.5275 14.5904L15.1075 13.1469C15.1799 12.9667 15.3501 12.8447 15.544 12.8341C15.7379 12.8234 15.9205 12.926 16.0122 13.0972L16.5851 14.1667H17C17.2761 14.1667 17.5 14.3905 17.5 14.6667C17.5 14.9428 17.2761 15.1667 17 15.1667H16.2857C16.1014 15.1667 15.932 15.0653 15.845 14.9028L15.6362 14.5131L14.964 16.1864C14.8865 16.3792 14.6979 16.504 14.4902 16.4999C14.2826 16.4959 14.099 16.3638 14.0291 16.1682L13.3862 14.3681L13.1739 14.8636C13.0951 15.0475 12.9143 15.1667 12.7143 15.1667H12C11.7239 15.1667 11.5 14.9428 11.5 14.6667C11.5 14.3905 11.7239 14.1667 12 14.1667H12.3846L12.969 12.803C13.05 12.614 13.2385 12.4939 13.444 12.5002Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M4.5 9.99998C4.5 9.72384 4.27614 9.49998 4 9.49998C3.72386 9.49998 3.5 9.72384 3.5 9.99998C3.5 12.869 5.69675 15.2249 8.5 15.4776V17.5C8.5 17.7761 8.72386 18 9 18C9.27614 18 9.5 17.7761 9.5 17.5V16.7945C9.179 16.0961 9 15.319 9 14.5C6.51472 14.5 4.5 12.4853 4.5 9.99998ZM12 5V9.59971C10.6546 10.2875 9.62981 11.513 9.20907 12.9928C9.14 12.9976 9.07028 13 9 13C7.34315 13 6 11.6568 6 9.99998V5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5ZM19 14.5C19 12.0147 16.9853 10 14.5 10C12.0147 10 10 12.0147 10 14.5C10 16.9853 12.0147 19 14.5 19C16.9853 19 19 16.9853 19 14.5ZM13.444 12.5002C13.6496 12.5066 13.8303 12.6382 13.8994 12.8318L14.5275 14.5904L15.1075 13.1469C15.1799 12.9667 15.3501 12.8447 15.544 12.8341C15.7379 12.8234 15.9205 12.926 16.0122 13.0972L16.5851 14.1667H17C17.2761 14.1667 17.5 14.3905 17.5 14.6667C17.5 14.9428 17.2761 15.1667 17 15.1667H16.2857C16.1014 15.1667 15.932 15.0653 15.845 14.9028L15.6362 14.5131L14.964 16.1864C14.8865 16.3792 14.6979 16.504 14.4902 16.4999C14.2826 16.4959 14.099 16.3638 14.0291 16.1682L13.3862 14.3681L13.1739 14.8636C13.0951 15.0475 12.9143 15.1667 12.7143 15.1667H12C11.7239 15.1667 11.5 14.9428 11.5 14.6667C11.5 14.3905 11.7239 14.1667 12 14.1667H12.3846L12.969 12.803C13.05 12.614 13.2385 12.4939 13.444 12.5002Z" />
      </g>
    </svg>
  ),
  displayName: 'MicPulseIcon',
});
